<template>
	<v-layout column>
		<v-flex shrink>
			<v-layout mt-2 px-3 row>
				<v-icon color="grey">info</v-icon>
				<v-layout column ml-3>
					<v-flex v-if="application && application.params_vendor" class="grey--text">{{ $t('application.import_info_with_params') }}</v-flex>
					<v-flex v-else-if="application" class="grey--text">{{ $t('application.import_info_without_params') }}</v-flex>
					<v-flex class="grey--text">{{ $t('application.import_warning') }}</v-flex>
				</v-layout>
			</v-layout>
		</v-flex>
		<v-flex shrink>
			<w-btn flat icon="download" :loading="exportLoading" small @click="downloadImportFile()">{{ $t('application.download_import_file') }}</w-btn>
		</v-flex>
		<v-flex shrink mt-2 px-3>
			<DropZone v-model="importFile" accepted-mime-types="text/csv" :text="$t('application.drop_import')" />
			<FileList :deletable="false" :value="importFile ? [importFile] : []" />
			<ErrorReport :value="importErrors" />
		</v-flex>
		<v-flex text-xs-right shrink>
			<w-btn :disabled="!importFile" icon="upload" :loading="importLoading" @click="sendImportFile()">{{ $t('actions.import') }}</w-btn>
		</v-flex>
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'

export default {
	name: 'ImportFormApplicationVendor',
	components: {
		DropZone: () => ({
			component: import('@/components/Commons/DropZone')
		}),
		ErrorReport: () => ({
			component: import('@/components/Applications/ErrorReport')
		}),
		FileList: () => ({
			component: import('@/components/Commons/FileList')
		})
	},
	mixins: [ApplicationsModuleGuard],
	props: {
		value: {
			default: null,
			required: false,
			type: Object
		}
	},
	data: function () {
		return {
			exportLoading: false,
			importErrors: [],
			importFile: null,
			importLoading: false
		}
	},
	computed: {
		application: function () {
			return this.value
		}
	},
	methods: {
		downloadImportFile: function () {
			this.exportLoading = true
			const fileName = `${this.$t('application.import_client_file')}-${this.application.title}`
			this.service.downloadImportFile(this.accountingFirmId, this.application?.catalog_application_id, fileName).finally(() => {
				this.exportLoading = false
			})
		},
		reset: function () {
			this.exportLoading = false
			this.importErrors.splice(0, this.importErrors.length)
			this.importFile = null
			this.importLoading = false
		},
		sendImportFile: function () {
			this.importLoading = true
			this.importErrors.splice(0, this.importErrors.length)
			this.service
				.importApplicationVendors(this.accountingFirmId, this.application.id, this.importFile, { show_error: false })
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.import_success'))
				})
				.catch(error => {
					const data = error?.response?.data
					if (Array.isArray(data?.errors)) {
						this.importErrors = data.errors
					} else {
						throw error
					}
				})
				.finally(() => {
					this.importLoading = false
				})
		}
	}
}
</script>